.main-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--green);
    padding-block: 1rem;
}

.main-footer p {
    margin: 0;
}

.main-footer p.small {
    font-size: .9rem;
}

.main-footer a {
    color: inherit;
}