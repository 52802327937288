/* HEADER */
.main-header {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  background-color: var(--header);
  height: auto;
  transition: all 0.3s ease-in;
  background-color: var(--lightgrey);
}

.main-header .logo {
  height: 8rem;
}

#burger-link {
  margin-inline: 1rem;

  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;

}

#burger-link svg {
  width: 4rem;
}

.line {
  fill: none;
  stroke: black;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.main-nav {
  grid-column: 1 / 3;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: all .5s ease-in-out;
}

.main-nav.open {
  opacity: 1;
  height: 100vh;
}

.nav-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  margin-block-start: 5rem;
  text-transform: uppercase;
  font-size: 2rem;
}

.nav-link {
  position: relative;
  font-size: 2.4rem;
  text-decoration: none;
  color: inherit;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -1rem;
  width: calc(100% + 2rem);
  height: 0.1em;
  background-color: var(--green);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.nav-link::after {
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}

.nav-link:hover::after,
.nav-link:focus::after{
  transform: scale(1);
}

@media (width > 650px) {
  /* #burger {
    width:1.5rem;
    height:.2rem;
    background: black;
    border-radius: 1rem;
    position: absolute;
    right: 2rem;
  } */
}