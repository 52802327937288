.App {
    display: grid;
    grid-template-areas: 
                "header"
                "body"
                "footer"
    ;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
    min-height: 100dvh;

    font-family: 'Enriqueta';

    background-color: var(--lightgrey);
}

.main-header {
    grid-area: header;
}

main {
    grid-area: body;
    padding-block-end: 2rem;
    background-color: var(--grey);
}

main .container {
    margin-inline: 2rem;
}

main section {
    margin-block: 2rem;
}

main article {
    margin-inline: 3rem;
    padding-inline-start: 1rem;
}

.main-footer {
    grid-area: footer;
}

a {
    color: blue;
}

@media (width > 1024px) {
    
    main .container {
        max-width: 100rem;
        margin-inline: auto;
    }
}