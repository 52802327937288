.teams article {
    display: grid;
    margin-block-end: 4rem;
    background-color: var(--green);
    box-shadow: .2rem .2rem 1rem var(--black);
    padding: 1rem;
    border-radius: 1rem;
    max-width: 90rem;
    margin-inline: auto;
}    

.teams article h2 {
    margin-block-end: 2rem;
}

.teams article .planning {
    margin-block-start: 3rem;
}

.teams article .planning ul {
    margin-block: 0;
}


.teams article .content {
    background-color: #b6da7e;
    padding: 1rem;
}

@media (width > 500px) {

    .teams article  {
        
        grid-template-columns: auto 1fr;
        grid-template-areas: 
                    "title title"
                    "image content"
        ;
        gap: 2rem;
    }

    .teams article h2 {
        grid-area: title;
        margin-block-end: 0;
    }

    .teams article img {
        max-width: 50rem;
        width: 100%;
    }
}