.contact {
    display: grid;
    gap: 3rem;
}

.contact .icons {
    display: flex;
    gap: 3rem;
    margin-block: 2rem;
}

.contact .icons a {
    max-width: 5rem;
}

@media (width > 500px) {
    
.contact .icons a {
    max-width: 8rem;
}
}