.hero {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: min(calc(100vw/4.2), 17rem);
}

.trending article img,
.news article img {
    cursor: pointer;
}

.trending article {
    display: grid;
    padding: 1rem;
    background-color: var(--green);
    border-radius: 1rem;
    box-shadow: .2rem .2rem .5rem black;
    font-weight: 700;
}

.news {
    display: grid;
    gap: 2rem;
}

.news article {
    display: grid;
    justify-items: center;
    padding: 1rem;
    background-color: var(--grey);
    border-radius: 1rem;
    box-shadow: .2rem .2rem 1rem grey;
}

.news article p {
    margin-block-end: 1rem;
}

.news article p::first-letter {
    font-size: 2rem;
}

.news article img {
    margin-block-end: 1rem;
}

.news .more {
    justify-self: center;
    display: flex;
    justify-content: center;
    width: 20rem;
    padding: .5rem 1rem;
    border-radius: 1rem;
    text-decoration: none;
    color: var(--black);
    background-color: var(--green);
    transition: all 300ms;
}

.news .more:hover {
    box-shadow: 0 0 .5rem .2rem var(--black);
}

@media (width > 500px) {
    
    .news .more {
        justify-self: end;
        margin-inline-end: 3rem;
    }
    
}

@media (width > 750px) {

    .news article {
        grid-template-columns: auto 1fr;
        gap: 3rem;
    }

    .trending article img {
        align-self: center;
        max-width: 100%;
    }
    .news article img {
        align-self: center;
        max-width: 25rem;
    }
    
}

@media (width > 1000px) {
    
    .hero {
        background-size: contain;
    }

}