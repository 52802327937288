.sponsors {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0;
}

.sponsors article {
    display: grid;
    justify-items: center;

    width: min(25rem, 100%);

    padding: 0;
}

.sponsors article a {
    display: flex;
    justify-content: center;
    margin-inline: 2rem;
    height: 15rem;
}

.sponsors article a img {
    flex-grow: 0;
    object-fit: contain;
}

@media (width > 700px) {
    .sponsors {
        gap: 5rem 2rem;
    }
}