.pagination {
    display: flex;
    justify-content: center;
    gap: 1rem;
    /* background-color: red; */
}

.pagination button {
    border: 0;
    padding: 0 1rem;
    font-size: 2rem;
}

.pagination button:hover {
    box-shadow: 0 0 5px 2px var(--green);
}

.pagination button.current {
    background-color: var(--green);
}

.pagination button.current:hover {
    box-shadow: 0 0 5px 2px var(--black);
}