/* Slideshow */

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
    margin-block-start: 5rem;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
}

.slide {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.slide img {
    max-width: 80%;
    max-height: 20rem;
    object-fit: scale-down;
    margin: auto;
}
  